import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import { Languages } from 'src/localization/Locale';
import { AppSession } from 'src/models/AppSession';
import {
    UserFormSubmissionState, UserFormSubmissionStateToString
} from 'src/models/dto/UserFormRequest';
import { Loading } from 'src/ui/foundation/Controls';
import { AttachmentForm } from 'src/ui/foundation/Controls/AttachmentForm';
import RichContentControl from 'src/ui/foundation/Controls/RichContentControl';
import richContentControlTester from 'src/ui/foundation/Controls/richContentControlTester';
import TableIdWithOffset from 'src/ui/foundation/Controls/TableIdWithOffset';
import tableIdWithOffsetTester from 'src/ui/foundation/Controls/tableIdWithOffsetTester';
import { TitleSelector } from 'src/ui/foundation/Controls/TitleSelector';
import { UserInfoBlock } from 'src/ui/foundation/Controls/UserInfoBlock';
import { AppContext, UserFormSubmissionContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';

import * as Models from '../../../models/dto/DashboardModels';
import * as Messages from '../../foundation/Messages';

export interface IBulletinFormProps {
  initialNode: Models.IUserFormViewModel;
  deleteRequested?: (node: Models.IUserFormSubmission) => void;
  saveRequested?: (node: Models.IUserFormSubmission, titles: Models.ITitle[], attac: Models.IUserFormSubmissionAttachment[]) => void;
  reloadBulletins?: () => void;
}

export interface IBulletinFormState {
  editingNode: Models.IUserFormSubmission;
  currentTitles: Models.ITitle[];
  currentAttachments: Models.IUserFormSubmissionAttachment[];
  submissionData: any;
  loading: boolean;
}

enum BulletinTab {
  Bulletin,
  Titles,
  Attachments,
}

export class BulletinForm extends React.Component<IBulletinFormProps, IBulletinFormState> {
  context: AppSession;
  static contextType = AppContext;

  constructor(props: IBulletinFormProps | Readonly<IBulletinFormProps>) {
    super(props);
    this.state = {
      submissionData: "",
      loading: false,
      currentAttachments: [],
      currentTitles: this.props.initialNode.AssociatedTitles,
      editingNode: {
        TableId: this.props.initialNode.TableId,
        CreationDate: this.props.initialNode.CreationDate,
        Submission: this.props.initialNode.Submission,
        UserReadReceiptNeeded: this.props.initialNode.UserReadReceiptNeeded === null ? false : this.props.initialNode.UserReadReceiptNeeded,
        UserFormDefinitionId: this.props.initialNode.UserFormDefinitionId,
        BreadCrumb: this.props.initialNode.BreadCrumb,
        LastModificationDate: this.props.initialNode.LastModificationDate,
        LastModificationUserId: this.props.initialNode.LastModificationUserId,
        SubmissionState: this.props.initialNode.SubmissionState,
        ApproverUserId: this.props.initialNode.ApproverUserId,
        SegmentId: null,
        TableGuid: this.props.initialNode.TableGuid,
        UserId: this.props.initialNode.UserId,
        VersionId: null,
      },
    };
  }
  async componentDidMount() {
    let attRes = await this.context.getAttachments({ TableId: this.state.editingNode.TableId });

    if (attRes.valid()) {
      this.setState({ currentAttachments: attRes.data.Attachments });
    }
  }

  titlesChanged = (titles: Models.ITitle[]) => {
    this.setState(() => ({
      currentTitles: titles,
    }));
  };

  changeTipState(newState: UserFormSubmissionState): void {
    if (this.props.deleteRequested) {
      this.setState({ loading: true }, async () => {
        let result = await this.context.changeUserFormState({ NewState: newState, UserFormSubmissionId: this.state.editingNode.TableId });
        if (result.valid()) {
          Messages.Notify.success("Submission changed successfully!");
          let current = this.state.editingNode;
          current.SubmissionState = newState;
          this.setState({ editingNode: current }, () =>
            this.setState({ editingNode: { ...this.state.editingNode, Submission: JSON.stringify(this.state.submissionData) } }, () =>
              this.props.saveRequested!(this.state.editingNode, this.state.currentTitles, this.state.currentAttachments)
            )
          );
        } else {
          if (result.errors.length > 0) {
            Messages.Notify.error("Fetch failed. Server reported: " + result.errors[0].Message);
          } else {
            Messages.Notify.error("An error occurred while executing the communication");
          }
        }
        this.setState({ loading: false });
      });
    }
  }

  handleNewAttachment = (e: Models.IUserFormSubmissionAttachment) => {
    let current = JSON.parse(JSON.stringify(this.state.currentAttachments));
    current.push(e);
    this.setState({ currentAttachments: current });
  };
  handleUpdatedAttachment = (e: Models.IUserFormSubmissionAttachment) => {
    let current = JSON.parse(JSON.stringify(this.state.currentAttachments));
    current.splice(
      this.state.currentAttachments.findIndex((x) => x.TableId === e.TableId),
      1
    );
    current.push(e);
    this.setState({ currentAttachments: current });
  };
  deleteAttachment = (e: Models.IUserFormSubmissionAttachment) => {
    let current = JSON.parse(JSON.stringify(this.state.currentAttachments));
    current.splice(
      this.state.currentAttachments.findIndex((x) => x.Index === e.Index),
      1
    );
    this.setState({ currentAttachments: current });
  };
  render() {
    const schema = JSON.parse(this.props.initialNode.DataSchema);
    const uischema = JSON.parse(this.props.initialNode.DashboardUISchema);

    return (
      <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading Bulletin...">
        <div className="form-container full-width full-height">
          <h3> Bulletin Management </h3>
          <h4>Current status: {UserFormSubmissionStateToString.UserFormSubmissionStateToString(this.state.editingNode.SubmissionState)}</h4>
          <div className="submissionInfoHeader">
            <span>
              <span style={{ fontWeight: 800 }}>Created on:</span> {Convert.dateToFormattedString(this.props.initialNode.CreationDate, Languages.English)} (UTC)
            </span>
            <span>
              <span style={{ fontWeight: 800 }}>Last modified on:</span>{" "}
              {Convert.dateToFormattedString(this.props.initialNode.LastModificationDate, Languages.English)} (UTC)
            </span>
          </div>
          <div className="bulletin-tabs">
            <Tabs defaultActiveKey={0} id="bulletinTabs">
              <Tab eventKey={BulletinTab.Bulletin} title={"Bulletin"}>
                <div className="full-width full-height bulletinProperties">
                  <h4>Created by</h4>
                  <UserInfoBlock User={this.props.initialNode.CreatedBy} />
                  {this.props.initialNode.ApprovedBy !== null && (
                    <React.Fragment>
                      <h4>Approved by</h4>
                      <UserInfoBlock User={this.props.initialNode.ApprovedBy} />
                    </React.Fragment>
                  )}
                  <span>
                    <span className="detailHeader">Location in content </span>
                    <span className="locationValue">{this.state.editingNode.BreadCrumb}</span>
                  </span>
                  <UserFormSubmissionContext.Provider value={this.state.editingNode}>
                    <JsonForms
                      schema={schema}
                      uischema={uischema}
                      data={this.state.submissionData === "" ? JSON.parse(this.state.editingNode.Submission) : this.state.submissionData}
                      renderers={[
                        ...materialRenderers,
                        { tester: richContentControlTester, renderer: RichContentControl },
                        { tester: tableIdWithOffsetTester, renderer: TableIdWithOffset },
                      ]}
                      cells={materialCells}
                      onChange={({ data }) => {
                        this.setState({ submissionData: data });
                      }}
                    />
                  </UserFormSubmissionContext.Provider>
                </div>
              </Tab>
              <Tab eventKey={BulletinTab.Titles} title={"Titles"}>
                <TitleSelector publisherId={this.props.initialNode.PublisherId} associatedTitles={this.state.currentTitles} onChange={this.titlesChanged} />
              </Tab>
              <Tab eventKey={BulletinTab.Attachments} title={"Attachments"}>
                <AttachmentForm
                  currentAttachments={this.state.currentAttachments}
                  parentSubmission={this.state.editingNode}
                  handleNewAttachment={this.handleNewAttachment}
                  handleUpdatedAttachment={this.handleUpdatedAttachment}
                  deleteAttachment={this.deleteAttachment}
                />
              </Tab>
            </Tabs>
            
            <FormGroup>
              <Col>
                <Row>
                  <Button
                    style={{ flex: "1", marginRight: "10px" }}
                    onClick={() => {
                      this.setState({ editingNode: { ...this.state.editingNode, Submission: JSON.stringify(this.state.submissionData) } }, () =>
                        this.props.saveRequested!(this.state.editingNode, this.state.currentTitles, this.state.currentAttachments)
                      );
                    }}
                    outline
                    color="info"
                  >
                    Save Bulletin Changes
                  </Button>
                  {this.state.editingNode.TableId > 0 && this.state.editingNode.SubmissionState !== UserFormSubmissionState.UnderReview && (
                    <Button
                      onClick={() => this.changeTipState(UserFormSubmissionState.UnderReview)}
                      style={{ flex: "1", marginRight: "15px" }}
                      outline
                      color="info"
                    >
                      Review Bulletin
                    </Button>
                  )}
                  {this.state.editingNode.TableId > 0 && this.state.editingNode.SubmissionState === UserFormSubmissionState.UnderReview && (                    <Button
                      onClick={() => this.changeTipState(UserFormSubmissionState.Published)}
                      style={{ flex: "1", marginRight: "15px" }}
                      outline
                      color="info"
                    >
                      Publish Bulletin
                    </Button>
                  )}
                  <Button
                    style={{ flex: "1", marginLeft: "10px" }}
                    onClick={() =>
                      this.setState((prev) => ({ editingNode: { ...prev.editingNode, UserReadReceiptNeeded: !prev.editingNode.UserReadReceiptNeeded } }))
                    }
                    outline
                    color="info"
                  >
                    Set bulletin as
                    {this.state.editingNode.UserReadReceiptNeeded === null || this.state.editingNode.UserReadReceiptNeeded === false
                      ? " critical"
                      : " non critical"}
                  </Button>
                  {this.state.editingNode.TableId > 0 && this.state.editingNode.SubmissionState !== UserFormSubmissionState.Rejected && (
                    <Button
                      style={{ flex: "1", marginLeft: "10px" }}
                      onClick={() => this.props.deleteRequested!(this.state.editingNode)}
                      outline
                      color="danger"
                    >
                      Reject Bulletin
                    </Button>
                  )}
                </Row>
              </Col>
            </FormGroup>
          </div>
        </div>
      </Loading>
    );
  }
}
