import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Languages } from "src/localization/Locale";
import { AppSession } from "src/models/AppSession";
import { DataItem, DataRow, DataTable } from "src/ui/foundation/DataTable";
import { Action, INode, IRequest, IResponse } from "src/ui/foundation/StandaloneCogniflow";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import * as Messages from "../../foundation/Messages";
import { VersionForm } from "./VersionForm";

export interface ITitleFormProps {
  initialNode: Models.ITitle;
  publisherName?: string;
  deleteRequested?: (node: Models.ITitle) => void;
  saveRequested?: (node: Models.ITitle) => void;
  reloadTitles: () => void;
}
export interface IITitleFormState {
  editingNode: Models.ITitle;
}
export class TitleForm extends React.Component<ITitleFormProps, IITitleFormState> {
  context: AppSession;
  constructor(props: ITitleFormProps) {
    super(props);
  }
  componentDidMount() {}

  render() {
    return (
      <div className="form-container full-width full-height">
        {this.props.deleteRequested ? (
          <EditTitleForm
            publisherName={this.props.publisherName!}
            initialNode={this.props.initialNode}
            deleteRequested={this.props.deleteRequested}
            saveRequested={this.props.saveRequested}
          />
        ) : (
          <CreateTitleForm initialNode={this.props.initialNode} saveRequested={this.props.saveRequested} reloadTitles={this.props.reloadTitles} />
        )}
      </div>
    );
  }
}
export interface IEditTitleFormProps {
  initialNode: Models.ITitle;
  publisherName: string;
  deleteRequested?: (node: Models.ITitle) => void;
  saveRequested?: (node: Models.ITitle) => void;
}
export interface IEditTitleFormState {
  editingNode: Models.ITitle;
  editingVersion: Models.IVersionViewModel | null;
  publicationPacks: Models.IPublicationPackViewModel[];
  activeTab: TitleTab;
  selectedPublisher: number;
  selectedTitle: number;
  versionMode: boolean;
  createMode: boolean;
  currentDrawerContent: JSX.Element | null;
  loading: boolean;
}
enum TitleTab {
  Info,
  Versions,
}
export class EditTitleForm extends React.Component<IEditTitleFormProps, IEditTitleFormState> {
  context: AppSession;
  static contextType = AppContext;
  publicationPacks: Models.IPublicationPackViewModel[];
  versionsTable = React.createRef<DataTable>();

  constructor(props: IEditTitleFormProps) {
    super(props);
    this.state = {
      editingNode: this.props.initialNode,
      publicationPacks: [],
      activeTab: TitleTab.Info,
      editingVersion: null,
      versionMode: false,
      createMode: false,
      currentDrawerContent: null,
      loading: false,
      selectedPublisher: 0,
      selectedTitle: 0,
    };
  }
  componentDidMount = () => {
    this.getPublicationPacks();
  };

  getPublicationPacks = async () => {
    let response = await this.context.getPublicationPacksByPublisher({
      PublisherId: this.state.editingNode.PublisherId,
    });
    if (response.valid()) {
      this.setState({
        publicationPacks: response.data.PublicationPacks,
      });
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };

  nameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Name: e.target.value,
      },
    }));
  };
  expirationDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        ExpirationDate: new Date(e.target.valueAsDate!),
      },
    }));
  };
  publicationDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        PublicationDate: new Date(e.target.valueAsDate!),
      },
    }));
  };
  titleStatusChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        TitleStatus: +e.target.value,
      },
    }));
  };
  publicationPackChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        PublicationPackId: Number(e.target.value),
      },
    }));
  };

  // #region Versions
  private initializeVersion = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowVersionsForTitle({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: this.state.editingNode.PublisherId,
        TitleId: this.state.editingNode.TableId,
        HideInactiveVersions: false,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  private versionFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowVersionsForTitle({
        FlowRequest: request.Batches[0],
        PublisherId: this.state.editingNode.PublisherId,
        TitleId: this.state.editingNode.TableId,
        HideInactiveVersions: false,
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });

  private generateVersion = (node: INode): JSX.Element => {
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];
    dataItems.push(<DataItem flexVal={2} key={1} value={node.Name} />);
    dataItems.push(<DataItem flexVal={1} key={2} className="leftBorder" value={Models.VersionStatus[node.VersionStatus]} />);
    dataItems.push(
      <DataItem flexVal={2} key={3} className="leftBorder" value={Convert.dateToFormattedString(node.EffectiveStartDate as Date, Languages.English)} />
    );
    dataItems.push(
      <DataItem flexVal={2} key={4} className="leftBorder" value={Convert.dateToFormattedString(node.EffectiveEndDate as Date, Languages.English)} />
    );

    return <DataRow node={node} key={node.TableId} attributes={attrs} dataItems={dataItems} rowEditRequested={this.versionRowEditRequest} />;
  };

  getVersionMeta = async (node: Models.IVersion) => {
    let response = await this.context.getVersionMeta({
      Version: node,
    });
    if (response.valid()) {
      let blank: Models.IVersionViewModel = {
        IsFirst: false,
        IsLast: false,
        Index: -1,

        Version: node,
        Metadata: response.data.VersionMeta,
      };
      this.setState({
        editingVersion: blank,
      });
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occured while executing the communication");
      }
    }
  };

  private versionRowEditRequest = (node: INode) => {
    this.getVersionMeta(node as Models.IVersion);
    this.setState({
      createMode: false,
      versionMode: true,
    });
  };

  private versionInsertRequest = () => {
    let blank: Models.IVersionViewModel = {
      Index: -1,
      IsFirst: false,
      IsLast: false,

      Version: {
        Index: -1,
        IsFirst: false,
        IsLast: false,

        Name: "",
        VersionStatus: Models.VersionStatus.New,
        EffectiveEndDate: new Date(),
        EffectiveStartDate: new Date(),
        TableGuid: "00000000-0000-0000-0000-000000000000",
        TableId: 0,
        TitleId: this.state.editingNode.TableId,
        ProtocolId: 0,
      },
      Metadata: {
        CoverImage: [],
        BackgroundImage: [],
        Title: "",
        Creator: "",
        Publisher: String(this.props.publisherName),
        Date: new Date(),
        Description: "",
        Language: "",
        Extent: "",
        Identifier: "",
        Subject: "",
        Type: "",
        Coverage: "",
        Contributor: "",
        Rights: "",
        Source: "",
        Format: "",
        URL: "",
        SavvyContext: ""
      },
    };
    this.setState({
      createMode: true,
      editingVersion: blank,
      versionMode: true,
    });
  };

  private reloadVersionAndDismiss = () => {
    this.versionsTable.current!.reload();
    this.setState({
      editingVersion: null,
      versionMode: false,
    });
  };
  // #endregion

  private versionQueryExecute = (query: string) => {
    this.versionsTable.current!.reload(query);
  };

  formValid = () => {
    if (this.state.editingNode.Name === null) {
      return false;
    }
    return true;
  };

  render() {
    let settings = Models.genericDataSettings;
    settings.batchSize = 25;
    if (this.state.versionMode && this.state.editingVersion !== null) {
      let versModel: Models.IVersionViewModel = JSON.parse(JSON.stringify(this.state.editingVersion));
      return (
        <VersionForm
          initialNode={versModel}
          createMode={this.state.createMode}
          reloadVersion={this.reloadVersionAndDismiss}
          goBackButtonClicked={() => this.setState({ editingVersion: null, versionMode: false })}
          publisherId={this.props.initialNode.PublisherId}
        />
      );
    }
    let publicationPacksList = this.state.publicationPacks;
    let pubPacksControl: JSX.Element;
    if (publicationPacksList.length > 0) {
      pubPacksControl = (
        <FormGroup check style={{ flex: "1" }}>
          <Label check for="PublicationPack">
            Publication Pack{" "}
          </Label>
          <Input
            onChange={this.publicationPackChanged}
            className={"pubSelect"}
            value={this.state.editingNode.PublicationPackId}
            type="select"
            name="publicationPack"
            id="publicationPack"
          >
            {this.state.publicationPacks.map((it) => (
              <option value={it.PublicationPack.TableId} key={it.PublicationPack.TableId} data-providerval={it.PublicationPack.TableId}>
                {it.PublicationPackDefinition.PackDefName}
              </option>
            ))}
          </Input>
        </FormGroup>
      );
    } else {
      pubPacksControl = (
        <FormGroup check style={{ flex: "1" }}>
          <Label check for="PublicationPack">
            Publication Pack{" "}
          </Label>
          <Input
            onChange={this.publicationPackChanged}
            className={"pubSelect"}
            value={this.state.editingNode.PublicationPackId}
            type="select"
            name="publicationPack"
            id="publicationPack"
          >
            {this.state.publicationPacks.map((it) => (
              <option value={it.PublicationPack.TableId} key={it.PublicationPack.TableId} data-providerval={it.PublicationPack.TableId}>
                {it.PublicationPackDefinition.PackDefName}
              </option>
            ))}
          </Input>
        </FormGroup>
      );
    }
    return (
      <div className="full-width full-height">
        {/* <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading Title..."> */}
        <h2>Title Management</h2>
        <p>You have selected {this.props.initialNode.Name} to consult or edit.</p>
        <p> Publisher: {this.props.publisherName}. </p>
        <div>
          <Tabs defaultActiveKey={this.state.activeTab} id="titleTabs">
            <Tab eventKey={TitleTab.Info} title={"Information"}>
              <div className="titlesTab">
                <Form>
                  <Col>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="name">
                          Name{" "}
                        </Label>
                        <Input onChange={this.nameChanged} type="text" value={this.state.editingNode.Name} name="name" id="name" width="8px" />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="tableGuid">
                          Table Guid{" "}
                        </Label>
                        <Input type="text" value={this.state.editingNode.TableGuid} name="tableGuid" id="tableGuid" width="8px" readOnly={true} />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="titleStatus">
                          Title Status{" "}
                        </Label>
                        <Input type="select" name="titleStatus" value={this.state.editingNode.TitleStatus} onChange={this.titleStatusChanged}>
                          {Object.keys(Models.TitleStatus)
                            .filter((key) => isNaN(Number(Models.TitleStatus[key as keyof typeof Models.TitleStatus])))
                            .map((it) => (
                              <option value={it} key={it} data-providerval={it}>
                                {Models.TitleStatus[it as keyof typeof Models.TitleStatus]}
                              </option>
                            ))}
                        </Input>
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="publicationDate">
                          Publication Date{" "}
                        </Label>
                        <Input
                          onChange={this.publicationDateChanged}
                          type="date"
                          value={Convert.formatDateForForm(new Date(this.state.editingNode.PublicationDate))}
                          name="publicationDate"
                          id="publicationDate"
                          width="8px"
                        />
                      </FormGroup>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="expirationDate">
                          Expiration Date{" "}
                        </Label>
                        <Input
                          onChange={this.expirationDateChanged}
                          type="date"
                          value={Convert.formatDateForForm(new Date(this.state.editingNode.ExpirationDate))}
                          name="expirationDate"
                          id="expirationDate"
                          width="8px"
                        />
                      </FormGroup>
                    </Row>
                    <Row>{pubPacksControl}</Row>
                  </Col>
                  <Col>
                    <Row className="formButtons">
                      {this.props.deleteRequested && (
                        <Button onClick={() => this.props.deleteRequested!(this.state.editingNode)} color="danger" outline>
                          Delete
                        </Button>
                      )}
                      {this.props.saveRequested && (
                        <Button disabled={!this.formValid()} onClick={() => this.props.saveRequested!(this.state.editingNode)} color="info" outline>
                          Save
                        </Button>
                      )}
                    </Row>
                  </Col>
                </Form>
              </div>
            </Tab>
            <Tab eventKey={TitleTab.Versions} title={"Versions"}>
              <div className="titlesTab">
                <DataTable
                  headers={["Name", "VersionStatus", "Effective Start Date", "Effective End Date"]}
                  headerFlexes={[2, 1, 2, 2]}
                  flowProvider={this.versionFlowProvider}
                  initializeFlowProvider={this.initializeVersion}
                  objectBuilder={this.generateVersion}
                  ref={this.versionsTable}
                  settingsOverride={settings}
                  searchQueryComitted={this.versionQueryExecute}
                  rowAddRequested={this.versionInsertRequest}
                />
              </div>
            </Tab>
          </Tabs>
        </div>
        {/* </Loading> */}
      </div>
    );
  }
}

export interface ICreateTitleFormProps {
  initialNode: Models.ITitle;
  saveRequested?: (node: Models.ITitle) => void;
  reloadTitles: () => void;
}
export interface ICreateTitleFormState {
  editingNode: Models.ITitle;
  publicationPacks: Models.IPublicationPackViewModel[];
}
export class CreateTitleForm extends React.Component<ICreateTitleFormProps, ICreateTitleFormState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: ICreateTitleFormProps) {
    super(props);
    this.state = { editingNode: this.props.initialNode, publicationPacks: [] };
  }

  componentDidMount = () => {
    this.getPublicationPacks();
  };
  getPublicationPacks = async () => {
    let response = await this.context.getPublicationPacksByPublisher({
      PublisherId: this.state.editingNode.PublisherId,
    });
    if (response.valid()) {
      this.setState({
        publicationPacks: response.data.PublicationPacks,
      });
      this.setState((prevState) => ({
        editingNode: {
          ...prevState.editingNode,
          PublicationPackId: response.data.PublicationPacks[0].PublicationPack.TableId,
        },
      }));
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  nameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Name: e.target.value,
      },
    }));
  };
  expirationDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        ExpirationDate: new Date(e.target.valueAsDate!),
      },
    }));
  };
  publicationPackChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        PublicationPackId: Number(e.target.value),
      },
    }));
  };
  publicationDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        PublicationDate: new Date(e.target.valueAsDate!),
      },
    }));
  };
  titleStatusChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        TitleStatus: +e.target.value,
      },
    }));
  };

  formValid = () => {
    if (this.state.editingNode.Name === null) {
      return false;
    }

    return true;
  };

  render() {
    return (
      <div>
        <h2>Title Management</h2>
        <p>You have chosen to create a new title. Enter the properties below and select a means of saving.</p>
        <Form>
          <Col>
            <Row>
              <FormGroup check style={{ flex: "1" }}>
                <Label check for="name">
                  Name{" "}
                </Label>
                <Input onChange={this.nameChanged} type="text" name="name" id="name" width="8px" />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup check style={{ flex: "1" }}>
                <Label check for="titleStatus">
                  Title Status{" "}
                </Label>
                <Input type="select" name="titleStatus" value={this.state.editingNode.TitleStatus} onChange={this.titleStatusChanged}>
                  {Object.keys(Models.TitleStatus)
                    .filter((key) => isNaN(Number(Models.TitleStatus[key as keyof typeof Models.TitleStatus])))
                    .map((it) => (
                      <option value={it} key={it} data-providerval={it}>
                        {Models.TitleStatus[it as keyof typeof Models.TitleStatus]}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup check style={{ flex: "1" }}>
                <Label check for="publicationDate">
                  Publication Date{" "}
                </Label>
                <Input onChange={this.publicationDateChanged} type="date" name="publicationDate" id="publicationDate" width="8px" />
              </FormGroup>
              <FormGroup check style={{ flex: "1" }}>
                <Label check for="expirationDate">
                  Expiration Date{" "}
                </Label>
                <Input onChange={this.expirationDateChanged} type="date" name="expirationDate" id="expirationDate" width="8px" value={Convert.formatDateForForm(new Date(this.state.editingNode.ExpirationDate))} />
              </FormGroup>
            </Row>
            <Row>
              {
                <FormGroup check style={{ flex: "1" }}>
                  <Label check for="PublicationPack">
                    Publication Pack{" "}
                  </Label>
                  <Input onChange={this.publicationPackChanged} className={"pubSelect"} type="select" name="publicationPack" id="publicationPack">
                    {this.state.publicationPacks.map((it) => (
                      <option value={it.PublicationPack.TableId} key={it.PublicationPack.TableId} data-providerval={it.PublicationPack.TableId}>
                        {it.PublicationPackDefinition.PackDefName}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              }
            </Row>
          </Col>
          <Col>
            <FormGroup style={{ flex: "1", display: "flex", justifyContent: "center" }}>
              <Row className="formButtons">
                <Button disabled={!this.formValid()} onClick={() => this.props.saveRequested!(this.state.editingNode)} color="info" outline>
                  Create
                </Button>
              </Row>
            </FormGroup>
          </Col>
        </Form>
      </div>
    );
  }
}
