import { ICogniflowOptionalSettings, INode } from "src/ui/foundation/StandaloneCogniflow";

import { UserFormSubmissionState, UserFormSubmissionType } from "./UserFormRequest";

export interface IAdminStatistics {
  AuthUserCount: number;
  CustomerCount: number;
  SystemEventCount: number;
  SystemEventsLast24Hours: number;
  SystemEventsLast7Days: number;
  PendingInvitations: number;
  AllLicenseCount: number;
  LicensesLast24Hours: number;
  LicensesLast7Days: number;
  PreUserCount: number;
  ProductCount: number;
  AllSubscriptionCount: number;
  SubscriptionsLast24Hours: number;
  SubscriptionsLast7Days: number;
  AllUserCount: number;
  UsersLast24Hours: number;
  UsersLast7Days: number;
  VersionsCount: number;
  TitlesCount: number;
}

export interface IPublisherStatistics {
  CustomerCount: number;
  AllLicenseCount: number;
  LicensesLast24Hours: number;
  LicensesLast7Days: number;
  ProductCount: number;
  AllSubscriptionCount: number;
  SubscriptionsLast24Hours: number;
  SubscriptionsLast7Days: number;
  VersionsCount: number;
  TitlesCount: number;
  AnnouncementsCount: number;
  StoreListingsSoldLast7Days: number;
}

export interface IPermission {
  UserId: number;
  TableId: number;
  GrantedByUserId: number;
  PublisherId: number | null;
  SubscriptionId: number | null;
  CreatedDate: Date;
  ExpirationDate: Date | null;
  ManageSystem: boolean;
  ManagePublishers: boolean;
  ManageProducts: boolean;
  ManageTitles: boolean;
  ManageSubscription: boolean;
  ManageAccounts: boolean;
  ManageBulletins: boolean;
  ManageFeedback: boolean;
  ManageTips: boolean;
  ManageOfflinePackages: boolean;
  ManageReporting: boolean;
  ManageContentReview: boolean;
  ManageAnnouncements: boolean;
  ManageStoreFront: boolean;
}

export interface IAboutInfo {
  TableId: number;
  LibraryInternationalizationTableId: number;
  Title: string;
  Html: string;
}

export interface ILibraryInternationalization {
  LibraryConfigId: number;
  Language: LanguageCode;
  Name: string;
  ResetPasswordUrl: string;
  RegistrationUrl: string;
  AccountNotActiveMessage: string;
  LoginCustomPlaceholder: string;
  LibraryNoTitlesMessage: string;
  LicenceAgreementLink: string;
  PrivacyPolicyLink: string;
  LoginFlavourText: string;
  TableId: number;
  AboutInfos: IAboutInfo[];
}

export interface IPublisher {
  Address: string;
  Address2: string;
  City: string;
  Province: string;
  Country: string;
  PostalCode: string;
  Phone: string;
  Phone2: string;
  Fax: string;
  Fax2: string;
  Email: string;
  Email2: string;
  SocialAddr: string;
  SocialAddr2: string;
  URL2: string;
  About: string;
  URL: string;
  Name: string;
  TableGuid: string;
  TableId: number;
}
export interface ILibraryConfig {
  PublisherId: number;
  PrintMaxWordCount: number;
  CopyMaxWordCountDaily: number;
  BrandKey: string;
  Version: string;
  ProductIdPrefix: string;
  StripePublicKey: string;
  StripePublicKeyTest: string;
  StripeStoreUrl: string;
  StripeStoreUrlTest: string;
  HideRegistrationLink: boolean;
  IsIAPEnabled: boolean;
  IsAnonymousEnabled: boolean;
  IsLoginEmailBased: boolean;
  IsExternallyAddable: boolean;
  IsRememberMeAllowed: boolean;
  IsServerUrlOverrideable: boolean;
  AnonymousRequiresToken: boolean;
  AuthorizationId: AuthProviderType;
  AuthorizationMethod: AuthMethod;
  URLPrefix: string;
  LastModified: Date;
  LoginLogo: number[];
  DefaultBookSplash: number[];
  LibraryIcon: number[];
  Theme: string;
  TableId: number;
  BuiltInLinkingOverride: string;
  BuiltInServerOverride: string;
  MicrosoftTenantOverride: string;
  IsMicrosoftSsoEnabled: boolean;
  AvailableTitlesSectionDisabled: boolean;
  AvailableTitlesSectionDefaultsCollapsed: boolean;
}

export interface ILoginLibrary {
  DisplayName: string;
  BrandKey: string;
  LoginLogo: string;
  LibraryIcon: string;
  PublisherId: number;
  Internationalizations: ILibraryInternationalization[];
  IsLoginEmailBased: boolean;
  SignUpUrl: string;
  ForgotPasswordUrl: string;
  AuthorizationId: AuthProviderType;
  AuthorizationMethod: AuthMethod;
}

export interface IDataPaging {
  Index: number;
  IsFirst: boolean;
  IsLast: boolean;
}

export interface IConfigurationPair extends IDataPaging, INode {
  Name: string;
  Value: string;
}
export interface IPublisherLookup extends IDataPaging, INode {
  PublisherId: number;
  Name: string;
  Titles: number;
  Products: number;
  Reference: string;
  BrandKey: string;
}
export interface IAuthenticationProvider extends IDataPaging, INode {
  TableId: number; // table PK
  Provider: AuthProviderType; // Enum value: SEXI.Common.AuthProviderType
  Method: AuthMethod; // Enum value: AuthMethod (see below)
  TemplateType: AuthTemplateType; // Enum value: AuthTemplateType (see below)
  Template: string; // the String.Format template to populate
}

export interface IOpenIddictApplication extends IDataPaging, INode {
  Id: string; // Guid
  DisplayName: string;
  ClientId: string;
  Permissions: string;
  ExpirationDate: Date;
  PublisherId: number;
}

export interface IUserFormViewModel extends IDataPaging, INode {
  TableId: number;
  CreationDate: Date;
  LastModificationDate: Date;
  ApprovalDate: Date;
  Submission: string;
  DataSchema: string;
  DashboardUISchema: string;
  LastModificationUserId: number | null;
  UserReadReceiptNeeded: boolean;
  UserFormDefinitionId: number;
  BreadCrumb: string;
  PublisherId: number;
  AssociatedTitles: ITitle[];
  UserFormSubmissionAttachments: IUserFormSubmissionAttachment[];
  SubmissionState: UserFormSubmissionState;
  ApproverUserId: number | null;
  UserId: number;
  TableGuid: string;
  CreatedBy: IUserAccount;
  ApprovedBy: IUserAccount | null;
  LastModifiedBy: IUserAccount | null;
}
export interface GlobalNoteViewModel extends IDataPaging, INode {
  TableId: number;
  TableGuid: string;
  UserId: number;
  VersionId: number;
  Type: number;
  Name: string;
  Note: string;
  Breadcrumb: string;
  LastModified: Date;
  CreationDate: Date;
  IsVisible: boolean;
  FriendlyNoteState: GlobalNoteState;
  State: GlobalNoteState;
  Creator: IUserAccount;
  Approver: IUserAccount;
  Modifier: IUserAccount;
  ApprovalDate: Date | null;
  ApproverUserId: number | null;
  LastModificationUserId: number | null;
  VersionState: string;
  VersionGuid: string;
}

export enum GlobalNoteState {
  Published,
  InReview,
  Rejected,
}

export interface IAnnouncementViewModel extends IDataPaging, INode {
  Comments: IAnnouncementComment[];
  AllTags: string[];
  SelectedTags: string[];
  Rating: number;
  NumberOfImpressions: number;
  Announcement: IAnnouncement;
  AssociatedTitles: ITitle[];
}

export interface IAnnouncement {
  TableId: number;
  TableGuid: string;
  Title: string;
  Content: string;
  PublisherId: number;
  CreationDate: Date;
  LastModificationDate: Date;
  AllowComments: boolean;
  AllowScoreDisplay: boolean;
  IsPublic: boolean;
  PublishBy: Date;
}

export interface IAnnouncementAttachment extends IDataPaging, INode {
  TableId: number;
  AnnouncementId: number;
  AttachmentExtension: string;
  AttachmentData: number[] | string;
  CreationDate: Date;
  LastModificationDate: Date;
  TableGuid: string;
  Name: string;
}

export interface ICommentViewModel extends IDataPaging, INode {
  Comment: IAnnouncementComment;
}
export interface IAnnouncementComment {
  TableId: number;
  UserId: number;
  UserName: string;
  AnnouncementId: number;
  Content: string;
  CreationDate: Date;
  LastModificationDate: Date;
  CommentState: number;
}

export interface IAnnouncementAttachment extends IDataPaging, INode {}

export interface IUserFormSubmission {
  TableId: number;
  UserId: number;
  VersionId: number | null;
  ApproverUserId: number | null;
  SegmentId: number | null;
  UserFormDefinitionId: number;
  TableGuid: string;
  UserReadReceiptNeeded: boolean | null;
  SubmissionState: UserFormSubmissionState;
  Submission: string;
  CreationDate: Date;
  BreadCrumb: string;
  LastModificationDate: Date;
  LastModificationUserId: number | null;
}

export interface IUserFormDefinition {
  TableId: number;
  TableGuid: string;
  PublisherId: number;
  FormType: UserFormSubmissionType;
  CreationDate: Date;
  IsActive: boolean;
  DataSchema: string;
  ReaderUiSchema: string;
  DashboardUiSchema: string;
}

export interface IUserFormSubmissionAttachment extends IDataPaging, INode {
  TableId: number;
  UserFormSubmissionId: number;
  AttachmentExtension: string;
  AttachmentData: number[] | string;
  CreationDate: Date;
  LastModificationDate: Date;
  TableGuid: string;
  Name: string;
}

export interface IUserCreate extends IUserAccount {
  Password: string;
  ConfirmPassword: string;
}
export interface IUserAccount {
  DateAdded: Date;
  DateLastUsed: Date;
  LockUntilDate: Date;
  TableId: number;
  TableGuid: string;
  DisplayName: string;
  Email: string;
  PhoneNumber: string;
  SocialAddr: string;
  SocialAddr2: string;
  LoginName: string;
  Occupation: string;
  LanguageType: LanguageCode;
  UserStatus: UserStatus;
  Locked: boolean;
  Industry: IndustryType;
  FirstName: string;
  LastName: string;
  IsSso: boolean;
  PromoConsent: boolean;
  PrivacyConsent: boolean;
}

export interface IAuthUser {
  UserId: number; // FK to User table -
  AuthProvider: AuthProviderType; // Enum value: which AuthProvider authenticated this user
  AuthUserId: string; // the foreign UserId as string from authentication provider
  AuthUserName: string; // the foreign UserName as string from login or authentication provider
  CustomerNumber: string; // so the customer can be found if deleting subscriptions is necessary
  SequentialNumber: number; // a number for this user and auth provider, used to generate user display name
}

export interface IUserViewModel extends IDataPaging, INode {
  User: IUserAccount;
  AuthUser: IAuthUser | null;
  Licenses: IUserLicenseViewModel[];
  Permissions: IPermissionViewModel[];
  AndroidVersion: string;
  iOSVersion: string;
  MacVersion: string;
  WindowsVersion: string;
  LastActive: Date;
}

export interface IUserLicenseViewModel extends IDataPaging, INode {
  License: ILicence;
  Subscription: ISubscription;
  Product: IProductDefinition;
}

export interface IPermissionViewModel extends IDataPaging, INode {
  Permission: IPermission;
  Publisher?: IPublisher;
  User?: IUserAccount;
  AuthUser?: IAuthUser;
}
export interface IAboutInfoViewModel extends IDataPaging, INode {
  AboutInfo: IAboutInfo;
}
export interface ILibraryInternationalizationViewModel extends IDataPaging, INode {
  LibraryInternationalization: ILibraryInternationalization;
}

export interface IInvitationViewModel {
  Invitation: IInvitation;
  PermissionModel?: IPermissionViewModel;
  Licence?: ILicenceInviteViewModel;
}

export interface ILicence {
  TableId: number;
  SubscriptionId: number;
  Status: LicenceStatus;
  LicenceRef: string;
  UserId: number;
  ProductConditionLock: number | null;
  ConditionLastCheck: Date | null;
  SubscriptionConditionLock: number | null;
}

export interface IInvitation {
  TableId: number;
  TableGuid: string;
  PublisherId: number;
  CreatedByUserId: number;
  CreatedDate: Date;
  ExpirationDate: Date;
  InvitationType: InvitationType;
  InvitationInstructions: string;
}

export interface IEmailTemplate {
  TableId: number;
  PublisherId: number;
  CreatedByUserId: number;
  TemplateName: string;
  Subject: string;
  EmailContents: string;
  TemplateType: EmailTemplateType;
  CreatedDate: Date;
}
export interface IEmailTemplateViewModel extends IDataPaging, INode {
  EmailTemplate: IEmailTemplate;
  Publisher: IPublisher | null;
}

export const genericDataSettings: ICogniflowOptionalSettings = {
  segmentDataDescriptor: {
    mainIdNodeAttribute: "Index",
    mainIdDataAttribute: "data-index",
    secondaryIdNodeAttribute: "Id",
    secondaryIdDataAttribute: "data-id",
    contentAttribute: "",
    isFirstAttribute: "IsFirst",
    isLastAttribute: "IsLast",
    applyDirectlyToSegment: true,
  },
  batchSize: 15,
  isIndexBased: true,
};

export interface IProductDefinition {
  TableId: number;
  StartDate: Date;
  EndDate: Date;
  OfflineAccessDuration: number;
  OfflineAccessDurationType: DurationType;
  OfflineLoginsNumber: number;
  MasterCode: string;
  ProductCode: string;
  ContentPermissions: number;
  AccessAfterExpiration: boolean;
  Name: string;
  PublisherId: number;
  Description: string;
  Thumbnail: string;
  AvailableForSale: boolean;
  IsFreeProduct: boolean;
}
export interface IProductDescription {
  TableId: number;
  PublisherId: number;
  ProductId: number;
  MasterCode: string;
  Description: string;
  Thumbnail: string;
}
export interface ICondition extends IDataPaging, INode {
  TableId: number;
  ConditionType: ProductConditionType;
  ConditionData: string;
}
export interface IProductCondition extends ICondition {
  DefinitionId: number;
}
export interface ISubscriptionCondition extends ICondition {
  ConditionAuthId: string;
  SubscriptionId: number;
}
export interface ITitle extends IDataPaging, INode {
  TableId: number;
  TableGuid: string;
  PublisherId: number;
  PublicationPackId: number;
  Name: string;
  TitleStatus: TitleStatus;
  PublicationDate: Date;
  ExpirationDate: Date;
}

export interface IVersion extends IDataPaging, INode {
  TableId: number;
  TableGuid: string;
  TitleId: number;
  Name: string;
  VersionStatus: VersionStatus;
  EffectiveStartDate: Date;
  EffectiveEndDate: Date;
  ProtocolId: number;
}

export interface IVersionMeta {
  CoverImage: number[];
  BackgroundImage: number[];
  Title: string;
  Creator: string;
  Publisher: string;
  Date: Date;
  Description: string;
  Language: string;
  Extent: string;
  Identifier: string;
  Subject: string;
  Type: string;
  Coverage: string;
  Contributor: string;
  Rights: string;
  Source: string;
  Format: string;
  URL: string;
  SavvyContext: string;
}

export interface IVersionViewModel extends IDataPaging {
  Version: IVersion;
  Metadata: IVersionMeta;
}

export interface IProductViewModel extends IDataPaging, INode {
  ProductDef: IProductDefinition;
  AssociatedTitlesCount: number;
  AssociatedTitles: ITitle[];
  Conditions: IProductCondition[];
}

export interface IPublisherPromotion {
  TableId: number;
  DefinitionId: number;
  StartDate: Date;
  EndDate: Date;
  Name: string;
  Description: string;
}

export interface IPublisherPromoViewModel extends IDataPaging, INode {
  ProductDef: IProductDefinition | null;
  Promotion: IPublisherPromotion;
}

export interface ICustomer {
  TableId: number;
  Name: string;
  CustomerNumber: string;
  PublisherId: number;
  Other: string;
  Address1: string;
  Address2: string;
  City: string;
  Province: string;
  Country: string;
  PostalCode: string;
  Web: string;
  Phone: string;
  Fax: string;
}
export interface IContact extends IDataPaging, INode {
  TableId: number;
  FirstName: string;
  LastName: string;
  Email: string;
  Language: number;
}
export interface ICustomerViewModel extends IDataPaging, INode {
  Customer: ICustomer;
  Contacts: IContact[];
  SubscriptionCount: number;
}
export interface ISubscription {
  TableId: number;
  StartDate: Date;
  EndDate: Date;
  OfflineAccessDuration: number;
  OfflineAccessDurationType: DurationType;
  OfflineLogins: number;
  ContentPermissions: number;
  AccessAfterExpiration: boolean;
  CustomerId: number;
  TotalLicences: number;
  DefinitionId: number;
  DateCreated: Date;
  Canceled: boolean;
  TableGuid: string;
  AuthSubscription: string;
}
export interface ISubscriptionViewModel extends IDataPaging, INode {
  Customer: ICustomer | null; // Only set in SubscriptionView flowing
  ProductDef: IProductDefinition | null;
  Subscription: ISubscription;
  Conditions: ISubscriptionCondition[];
  AssignedLicences: ILicence[];
}

export interface ILicenceViewModel extends IDataPaging, INode {
  Licence: ILicence;
  OwnerUsername: string;
}

export interface ILicenceInviteViewModel {
  Licence: ILicence;
  Subscription: ISubscription;
  Product: IProductDefinition;
}

export interface IPublicationPack {
  TableId: number;
  TableGuid: string;
  PublicationPackDefinitionId: number;
  PublisherId: number;
  Slots: number;
  FrontListPricePerAnnum: number;
  BackListPricePerAnnum: number;
  MaintenancePricePerAnnum: number;
  DateCreated: Date;
  LastBilled: Date;
  NextBilling: Date;
  RemainingSlots: number;
  PackName: string;
  PackDescription: string;
}

export interface IPublicationPackDefinition {
  TableId: number;
  PackDefName: string;
  PackDefDescription: string;
  UserLicenseInvoicing: UserLicenseInvoicing;
  BillingCurrency: CurrencyCodes;
  PublicationPackInvoicing: PublicationPackInvoicing;
}

export interface IUserLicenseRange {
  TableId: number;
  PublicationPackDefinitionId: number;
  StartRange: number;
  FrontListPricePerUser: number;
  BackListPricePerUser: number;
  MaintenancePricePerUser: number;
}
export interface IPublicationPackDefinitionViewModel extends IDataPaging, INode {
  PublicationPacks: IPublicationPack[];
  PublicationPackDefinition: IPublicationPackDefinition;
  UserLicenseRanges: IUserLicenseRange[];
}
export interface IPublicationPackViewModel extends IDataPaging, INode {
  PublicationPack: IPublicationPack;
  PublicationPackDefinition: IPublicationPackDefinition;
  UserLicenseRanges: IUserLicenseRange[];
  CalculatedPublicationsCost: number;
  CalculatedUserLicensingCost: number;

  // --- Statistics ---
  FrontListSlotsInUse: number;
  FrontListSubtotal: number;
  BackListSlotsInUse: number;
  BackListSubtotal: number;
  MaintenanceSlotsInUse: number;
  MaintenanceSubtotal: number;
  EmptySlotCostPerSlot: number;
  LicensesForInterval: number;
}
export interface IUserLicenseRangeViewModel extends IDataPaging, IUserLicenseRange, INode {
  EndRange: number;
}

export interface ILicensingToken {
  TableId: number;
  TableGuid: string;
  CreationDate: Date;
  ExpirationDate: Date;
}

export interface IStoreListingViewModel extends IDataPaging, INode {
  StoreListing: IStoreListing;
  TermLengths: IStoreListingTermLength[];
}

export interface IStoreListing {
  TableId: number;
  TableGuid: string;
  PublisherId: number;
  ProductId: number;
  Name: string;
  Description: string;
  StartDate: Date;
  EndDate: Date;
  IsActive: boolean;
  IsSpotlight: boolean;
  Tags: string[];
}

export interface IStoreListingReview extends IDataPaging, INode {
  TableId: number;
  TableGuid: string;
  StoreListingId: number;
  UserId: number;
  UserName: string;
  ReviewDate: Date;
  ReviewText: string;
  Rating: number;
}

export interface IStoreListingTermSale {
  TableId: number;
  TableGuid: string;
  StoreListingTermLengthId: number;
  StartDate: Date;
  EndDate: Date;
  SalePrice: number;
  IsActive: boolean;
}

export interface IStoreListingTermLength {
  TableId: number;
  TableGuid: string;
  StoreListingId: number;
  Price: number;
  TermLength: number;
  IsActive: boolean;
  HasBeenActivated: boolean;
  LastModificationDate: Date;
}

export interface IStoreListingTermSaleViewModel extends IDataPaging, INode {
  TermSale: IStoreListingTermSale;
  TermLength: IStoreListingTermLength;
}

export interface IStoreListingTermLengthViewModel extends IDataPaging, INode {
  StoreListing: IStoreListing;
  Term: IStoreListingTermLength;
}

export interface IStoreListingPerformanceViewModel extends IDataPaging, INode {
  StoreListing: string;
  TotalSales: number;
}

export interface IStoreListingPerformanceViewModel extends IDataPaging, INode {
  StoreListing: string;
  TotalSales: number;
}

export interface IStoreListingSaleFeedViewModel {
  Listing: string;
  Credits: number;
  PurchaseDate: Date;
}

export interface IAffiliateCodePerformanceViewModel extends IDataPaging, INode {
  AffiliateCode: string;
  TotalSales: number;
}

export interface IRegisteredAffiliate extends IDataPaging, INode {
  TableId: number;
  TableGuid: string;
  PublisherId: number;
  AffiliateCode: string;
  StartDate: Date;
  EndDate: Date;
  ReductionType: number;
  IsActive: boolean;
  ReductionAmount: number;
}

export interface IRoyaltyTermViewModel extends IRoyaltyTerm, IDataPaging, INode {
  PublisherName: string;
}

export interface IRoyaltyTerm {
  TableId: number;
  TableGuid: string;
  PublisherId: number;
  StartDate: Date;
  EndDate: Date;
  NewRoyalty: number;
}

// #region Enums
export enum UserLicenseInvoicing {
  RecurringPerAnnum,
  OneTimeInvoice,
}
export enum PublicationPackInvoicing {
  FlatPerAnnum,
  PerPublication,
}
export enum TitleStatus {
  Inactive = 1,
  Review = 2,
  Active = 3,
}
export enum VersionStatus {
  New = 1,
  Review = 2,
  Release = 3,
  Rescinded = 4,
  Inactive = 5,
  Publishing = 6,
  Releasing = 7,
}
export enum ProductConditionType {
  IpList,
  IpRange,
  Concurrent,
  ConcurrentIpRange,
  ConcurrentIpList,
}
export enum DurationType {
  Undefined = 0,
  Hours = 1,
  Days = 2,
  Weeks = 3,
  Months = 4,
  Years = 5,
  Permanent = 6,
}
export enum QueryArgType {
  Invite,
  AccountConfirm,
  PasswordReset,
  VanillaRegistration,
  AccountDeletion,
}
export enum EmailTemplateType {
  Undefined,
  PermissionInvite,
  LicenceInvite,
  AccountConfirm,
  PasswordReset,
  SystemMessage,
  LogSubmissionResponse,
}
export enum InvitationType {
  Permission,
  Licence,
  AccountConfirm,
  PasswordReset,
}
export enum LicenceStatus {
  Available = 0, // neither assigned nor pending (not invited to, not accepted)
  Assigned = 1, // invitation sent and accepted.  A Consumer is assigned to this Licence
  Pending = 2, // invitation has been sent but not responded to yet for this licence
  Error = 99,
}
export enum LoginResult {
  NotSet,
  Online,
  Offline,
  InvalidCredentials,
  UnknownError,
  AccountNotActive,
  OnlineAnonymous,
  OfflineAnonymous,
  ConnectionFailed,
}
export enum UserStatus {
  Active = 1,
  Inactive = 2,
  Pending = 3,
  Revoked = 4,
}
export enum AuthProviderType {
  AuthUndefined = 0, // This is an invalid value for LOGIN_EXT_REQUEST
  AuthSyncServer = 1, // We provide the authentication (same as the original LOGIN_REQUEST)
  AuthCTF_iMIS = 2, // the custom iMIS authentication entry point for Cognilore when authenticating CTF publications
  AuthIQPF = 3, // IQPF authentication
  AuthIQPFToken = 4, // IQPF authentication using tokn passed from IQPF site after a login occured there.
  AuthOUP = 5, // Oxford University Press
  AuthCarswell = 6, // Carswell/CPI auth via our provider endpoint addition to legacy system
  AuthEmond = 7, // Emond Publishing via Emond web api auth service
}
export enum AuthMethod {
  Undefined = 0,
  Normal = 1, // communication is a normal login authentication
  Background = 2, // communication is a background login authentication
  Test = 4, // communication is to a test interface
  Production = 8, // communication is to a live interface
  Fulfillment = 16, // communication is to fulfillment api
}
export enum AuthTemplateType {
  Undefined = 0,
  URL = 1, // format is a String.Format template with optional placeholders for parameters
  Data = 2, // format is a String.Format template with optional placeholders for parameters
  Headers = 3, // format is semicolon-separated list of name:value pairs of standard HTTP headers, as a String.Format template with optional placeholders for parameters
  APIUserName = 4, // format is base64 encoded byte array holding encrypted string
  APIPassword = 5, // format is base64 encided byte array holding encrypted string
  Endpoint = 6, // name of endpoint configuration to use
}

export enum LogType {
  Info,
  Debug,
  Error,
  Fatal,
  Warning,
}
export enum InviteType {
  DirectCreateUsername,
  DirectCreateUserId,
  InviteLink,
  InviteEmail,
  InviteCsv
}
export enum IndustryType {
  Undefined = 0, // placeholder, don't use this value

  // LinkedIn groups and industries
  Accounting = 1, // 47 corp fin Accounting
  Airlines_Aviation = 2, // 94 man tech tran Airlines_Aviation
  Alternative_Dispute_Resolution = 3, // 120 leg org Alternative Dispute Resolution
  Alternative_Medicine = 4, // 125 hlth Alternative Medicine
  Animation = 5, // 127 art med Animation
  Apparel_Fashion = 6, // 19 good Apparel_Fashion
  Architecture_Planning = 7, // 50 cons Architecture_Planning
  Arts_and_Crafts = 8, // 111 art med rec Arts and Crafts
  Automotive = 9, // 53 man Automotive
  Aviation_Aerospace = 10, // 52 gov man Aviation_Aerospace
  Banking = 11, // 41 fin Banking
  Biotechnology = 12, // 12 gov hlth tech Biotechnology
  Broadcast_Media = 13, // 36 med rec Broadcast Media
  Building_Materials = 14, // 49 cons Building Materials
  Business_Supplies_And_Equipment = 15, // 138 corp man Business Supplies and Equipment
  Capital_Markets = 16, // 129 fin Capital Markets
  Chemicals = 17, // 54 man Chemicals
  Civic_Social_Organization = 18, // 90 org serv Civic_Social Organization
  Civil_Engineering = 19, // 51 cons gov Civil Engineering
  Commercial_Real_Estate = 20, // 128 cons corp fin Commercial Real Estate
  Computer_Network_Security = 21, // 118 tech Computer_Network Security
  Computer_Games = 22, // 109 med rec Computer Games
  Computer_Hardware = 23, // 3 tech Computer Hardware
  Computer_Networking = 24, // 5 tech Computer Networking
  Computer_Software = 25, // 4 tech Computer Software
  Construction = 26, // 48 cons Construction
  Consumer_Electronics = 27, // 24 good man Consumer Electronics
  Consumer_Goods = 28, // 25 good man Consumer Goods
  Consumer_Services = 29, // 91 org serv Consumer Services
  Cosmetics = 30, // 18 good Cosmetics
  Dairy = 31, // 65 agr Dairy
  Defense_Space = 32, // 1 gov tech Defense_Space
  Design = 33, // 99 art med Design
  Education_Management = 34, // 69 edu Education Management
  E_Learning = 35, // 132 edu org E_Learning
  Electrical_Electronic_Manufacturing = 36, // 112 good man Electrical_Electronic Manufacturing
  Entertainment = 37, // 28 med rec Entertainment
  Environmental_Services = 38, // 86 org serv Environmental Services
  Events_Services = 39, // 110 corp rec serv Events Services
  Executive_Office = 40, // 76 gov Executive Office
  Facilities_Services = 41, // 122 corp serv Facilities Services
  Farming = 42, // 63 agr Farming
  Financial_Services = 43, // 43 fin Financial Services
  Fine_Art = 44, // 38 art med rec Fine Art
  Fishery = 45, // 66 agr Fishery
  Food_Beverages = 46, // 34 rec serv Food_Beverages
  Food_Production = 47, // 23 good man serv Food Production
  Fund_Raising = 48, // 101 org Fund_Raising
  Furniture = 49, // 26 good man Furniture
  Gambling_Casinos = 50, // 29 rec Gambling_Casinos
  Glass_Ceramics_Concrete = 51, // 145 cons man Glass Ceramics_Concrete
  Government_Administration = 52, // 75 gov Government Administration
  Government_Relations = 53, // 148 gov Government Relations
  Graphic_Design = 54, // 140 art med Graphic Design
  Health_Wellness_And_Fitness = 55, // 124 hlth rec Health Wellness and Fitness
  Higher_Education = 56, // 68 edu Higher Education
  Hospital_Health_Care = 57, // 14 hlth Hospital_Health Care
  Hospitality = 58, // 31 rec serv tran Hospitality
  Human_Resources = 59, // 137 corp Human Resources
  Import_and_Export = 60, // 134 corp good tran Import and Export
  Individual_Family_Services = 61, // 88 org serv Individual_Family Services
  Industrial_Automation = 62, // 147 cons man Industrial Automation
  Information_Services = 63, // 84 med serv Information Services
  Information_Technology_and_Services = 64, // 96 tech Information Technology and Services
  Insurance = 65, // 42 fin Insurance
  International_Affairs = 66, // 74 gov International Affairs
  International_Trade_and_Development = 67, // 141 gov org tran International Trade and Development
  Internet = 68, // 6 tech Internet
  Investment_Banking = 69, // 45 fin Investment Banking
  Investment_Management = 70, // 46 fin Investment Management
  Judiciary = 71, // 73 gov leg Judiciary
  Law_Enforcement = 72, // 77 gov leg Law Enforcement
  Law_Practice = 73, // 9 leg Law Practice
  Legal_Services = 74, // 10 leg Legal Services
  Legislative_Office = 75, // 72 gov leg Legislative Office
  Leisure_Travel_Tourism = 76, // 30 rec serv tran Leisure Travel_Tourism
  Libraries = 77, // 85 med rec serv Libraries
  Logistics_and_Supply_Chain = 78, // 116 corp tran Logistics and Supply Chain
  Luxury_Goods_Jewelry = 79, // 143 good Luxury Goods_Jewelry
  Machinery = 80, // 55 man Machinery
  Management_Consulting = 81, // 11 corp Management Consulting
  Maritime = 82, // 95 tran Maritime
  Market_Research = 83, // 97 corp Market Research
  Marketing_and_Advertising = 84, // 80 corp med Marketing and Advertising
  Mechanical_Industrial_Engineering = 85, // 135 cons gov man Mechanical or Industrial Engineering
  Media_Production = 86, // 126 med rec Media Production
  Medical_Devices = 87, // 17 hlth Medical Devices
  Medical_Practice = 88, // 13 hlth Medical Practice
  Mental_Health_Care = 89, // 139 hlth Mental Health Care
  Military = 90, // 71 gov Military
  Mining_Metals = 91, // 56 man Mining_Metals
  Motion_Pictures_and_Film = 92, // 35 art med rec Motion Pictures and Film
  Museums_and_Institutions = 93, // 37 art med rec Museums and Institutions
  Music = 94, // 115 art rec Music
  Nanotechnology = 95, // 114 gov man tech Nanotechnology
  Newspapers = 96, // 81 med rec Newspapers
  Non_Profit_Organization_Management = 97, // 100 org Non_Profit Organization Management
  Oil_Energy = 98, // 57 man Oil_Energy
  Online_Media = 99, // 113 med Online Media
  Outsourcing_Offshoring = 100, // 123 corp Outsourcing_Offshoring
  Package_Freight_Delivery = 101, // 87 serv tran Package_Freight Delivery
  Packaging_and_Containers = 102, // 146 good man Packaging and Containers
  Paper_Forest_Products = 103, // 61 man Paper_Forest Products
  Performing_Arts = 104, // 39 art med rec Performing Arts
  Pharmaceuticals = 105, // 15 hlth tech Pharmaceuticals
  Philanthropy = 106, // 131 org Philanthropy
  Photography = 107, // 136 art med rec Photography
  Plastics = 108, // 117 man Plastics
  Political_Organization = 109, // 107 gov org Political Organization
  Primary_Secondary_Education = 110, // 67 edu Primary_Secondary Education
  Printing = 111, // 83 med rec Printing
  Professional_Training_Coaching = 112, // 105 corp Professional Training_Coaching
  Program_Development = 113, // 102 corp org Program Development
  Public_Policy = 114, // 79 gov Policy
  Public_Relations_and_Communications = 115, // 98 corp Relations and Communications
  Public_Safety = 116, // 78 gov Safety
  Publishing = 117, // 82 med rec Publishing
  Railroad_Manufacture = 118, // 62 man Railroad Manufacture
  Ranching = 119, // 64 agr Ranching
  Real_Estate = 120, // 44 cons fin good Real Estate
  Recreational_Facilities_and_Services = 121, // 40 rec serv Recreational Facilities and Services
  Religious_Institutions = 122, // 89 org serv Religious Institutions
  Renewables_Environment = 123, // 144 gov man org Renewables_Environment
  Research = 124, // 70 edu gov Research
  Restaurants = 125, // 32 rec serv Restaurants
  Retail = 126, // 27 good man Retail
  Security_and_Investigations = 127, // 121 corp org serv Security and Investigations
  Semiconductors = 128, // 7 tech Semiconductors
  Shipbuilding = 129, // 58 man Shipbuilding
  Sporting_Goods = 130, // 20 good rec Sporting Goods
  Sports = 131, // 33 rec Sports
  Staffing_and_Recruiting = 132, // 104 corp Staffing and Recruiting
  Supermarkets = 133, // 22 good Supermarkets
  Telecommunications = 134, // 8 gov tech Telecommunications
  Textiles = 135, // 60 man Textiles
  Think_Tanks = 136, // 130 gov org Think Tanks
  Tobacco = 137, // 21 good Tobacco
  Translation_and_Localization = 138, // 108 corp gov serv Translation and Localization
  Transportation_Trucking_Railroad = 139, // 92 tran Transportation_Trucking_Railroad
  Utilities = 140, // 59 man Utilities
  Venture_Capital_Private_Equity = 141, // 106 fin tech Venture Capital_Private Equity
  Veterinary = 143, // 16 hlth Veterinary
  Warehousing = 143, // 93 tran Warehousing
  Wholesale = 144, // 133 good Wholesale
  Wine_and_Spirits = 145, // 142 good man rec Wine and Spirits
  Wireless = 146, // 119 tech Wireless
  Writing_and_Editing = 147, // 103 art med rec Writing and Editing
}
export enum LanguageCode {
  Undefined = 0, //  indicates an unknown language
  Abkhaz = 1,
  Afar = 2,
  Afrikaans = 3,
  Akan = 4,
  Albania = 5,
  Amharic = 6,
  Arabic = 7,
  Aragonese = 8,
  Armenian = 9,
  Assamese = 10,
  Avaric = 11,
  Avestan = 12,
  Aymara = 13,
  Azerbaijani = 14,
  Bambara = 15,
  Bashkir = 16,
  Basque = 17,
  Belarusian = 18,
  Bengali = 19,
  Bihari = 20,
  Bislama = 21,
  Bosnian = 22,
  Breton = 23,
  Bulgarian = 24,
  Burmese = 25,
  Catalan = 26,
  Chamorro = 27,
  Chechen = 28,
  Chichewa = 29,
  Chinese = 30,
  Chuvash = 31,
  Cornish = 32,
  Corsican = 33,
  Cree = 34,
  Croatian = 35,
  Czech = 36,
  Danish = 37,
  Divehi = 38,
  Dutch = 39,
  Dzongkha = 40,
  English = 41,
  Esperanto = 42,
  Estonian = 43,
  Ewe = 44,
  Faroese = 45,
  Fijian = 46,
  Finnish = 47,
  French = 48,
  Fula = 49,
  Galician = 50,
  Ganda = 51,
  Georgian = 52,
  German = 53,
  Greek = 54,
  Guarani = 55,
  Gujarati = 56,
  Haitian = 57,
  Hausa = 58,
  Hebrew = 59,
  Herero = 60,
  Hindi = 61,
  HiriMotu = 62,
  Hungarian = 63,
  Icelandic = 64,
  Ido = 65,
  Igbo = 66,
  Indonesian = 67,
  Interlingua = 68,
  Interlingue = 69,
  Inuktitut = 70,
  Inupiaq = 71,
  Irish = 72,
  Italian = 73,
  Japanese = 74,
  Javanese = 75,
  Kalaallisut = 76,
  Kannada = 77,
  Kanuri = 78,
  Kashmiri = 79,
  Kazakh = 80,
  Khmer = 81,
  Kikuyu = 82,
  Kinyarwanda = 83,
  Kirundi = 84,
  Komi = 85,
  Kongo = 86,
  Korean = 87,
  Kurdish = 88,
  Kwanyama = 89,
  Kyrgyz = 90,
  Lao = 91,
  Latin = 92,
  Latvian = 93,
  Limburgish = 94,
  Lingala = 95,
  Lithuanian = 96,
  LubaKatanga = 97,
  Luxembourgish = 98,
  Macedonian = 99,
  Malagasy = 100,
  Malay = 101,
  Malayalam = 102,
  Maltese = 103,
  Manx = 104,
  Maori = 105,
  Marathi = 106,
  Marshallese = 107,
  Mongolian = 108,
  Nauru = 109,
  Navajo = 110,
  Ndonga = 111,
  Nepali = 112,
  NorthernSami = 113,
  NorthNdebele = 114,
  Norwegian = 115,
  NorwegianBokmål = 116,
  NorwegianNynorsk = 117,
  Nuosu = 118,
  Occitan = 119,
  Ojibwe = 120,
  OldChurchSlavonic = 121,
  Oriya = 122,
  Oromo = 123,
  Ossetian = 124,
  Pali = 125,
  Panjabi = 126,
  Pashto = 127,
  Persian = 128,
  Polish = 129,
  Portuguese = 130,
  Quechua = 131,
  Romanian = 132,
  Romansh = 133,
  Russian = 134,
  Samoan = 135,
  Sango = 136,
  Sanskrit = 137,
  Sardinian = 138,
  ScottishGaelic = 139,
  Serbian = 140,
  Shona = 141,
  Sindhi = 142,
  Sinhala = 143,
  Slovak = 144,
  Slovene = 145,
  Somali = 146,
  SouthernSotho = 147,
  SouthNdebele = 148,
  Spanish = 149,
  Sundanese = 150,
  Swahili = 151,
  Swati = 152,
  Swedish = 153,
  Tagalog = 154,
  Tahitian = 155,
  Tajik = 156,
  Tamil = 157,
  Tatar = 158,
  Telugu = 159,
  Thai = 160,
  TibertanStandard = 161,
  Tigrinya = 162,
  Tonga = 163,
  Tsonga = 164,
  Tswana = 165,
  Turkish = 166,
  Turkmen = 167,
  Twi = 168,
  Ukrainian = 169,
  Ulighur = 170,
  Urdu = 171,
  Uzbek = 172,
  Venda = 173,
  Vietnamese = 174,
  Volapük = 175,
  Walloon = 176,
  Welsh = 177,
  WesternFrisian = 178,
  Wolof = 179,
  Xhosa = 180,
  Yiddish = 181,
  Yoruba = 182,
  Zhaung = 183,
  Zulu = 184,
}
/// <summary>
/// Enumeration of ISO 4217 currency codes, indexed with their respective ISO 4217 numeric currency codes.
/// Only codes support in .Net with RegionInfo objects are listed
/// </summary>
export enum CurrencyCodes {
  AED = 784,
  AFN = 971,
  ALL = 8,
  AMD = 51,
  ARS = 32,
  AUD = 36,
  AZN = 944,
  BAM = 977,
  BDT = 50,
  BGN = 975,
  BHD = 48,
  BND = 96,
  BOB = 68,
  BRL = 986,
  BYR = 974,
  BZD = 84,
  CAD = 124,
  CHF = 756,
  CLP = 152,
  CNY = 156,
  COP = 170,
  CRC = 188,
  CZK = 203,
  DKK = 208,
  DOP = 214,
  DZD = 12,
  //    EEK = 233,
  EGP = 818,
  ETB = 230,
  EUR = 978,
  GBP = 826,
  GEL = 981,
  GTQ = 320,
  HKD = 344,
  HNL = 340,
  HRK = 191,
  HUF = 348,
  IDR = 360,
  ILS = 376,
  INR = 356,
  IQD = 368,
  IRR = 364,
  ISK = 352,
  JMD = 388,
  JOD = 400,
  JPY = 392,
  KES = 404,
  KGS = 417,
  KHR = 116,
  KRW = 410,
  KWD = 414,
  KZT = 398,
  LAK = 418,
  LBP = 422,
  LKR = 144,
  LTL = 440,
  //    LVL = 428,
  LYD = 434,
  MAD = 504,
  MKD = 807,
  MNT = 496,
  MOP = 446,
  MVR = 462,
  MXN = 484,
  MYR = 458,
  NIO = 558,
  NOK = 578,
  NPR = 524,
  NZD = 554,
  OMR = 512,
  PAB = 590,
  PEN = 604,
  PHP = 608,
  PKR = 586,
  PLN = 985,
  PYG = 600,
  QAR = 634,
  RON = 946,
  RSD = 941,
  RUB = 643,
  RWF = 646,
  SAR = 682,
  SEK = 752,
  SGD = 702,
  SYP = 760,
  THB = 764,
  TJS = 972,
  TND = 788,
  TRY = 949,
  TTD = 780,
  TWD = 901,
  UAH = 980,
  USD = 840,
  UYU = 858,
  UZS = 860,
  VEF = 937,
  VND = 704,
  XOF = 952,
  YER = 886,
  ZAR = 710,
  //    ZWL = 932
}
// #endregion
