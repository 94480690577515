import * as React from "react";
import { Single } from "src/collections/Observable";
import { AppSession, LoginType } from "src/models/AppSession";

import { Image } from "../foundation/Assets";
import { Icon } from "../foundation/Controls";
import { DashboardView } from "../foundation/Layout";
import { AppContext } from "../state/Contextes";

interface ISidebarViewProps {
  currentView: Single<DashboardView>;
}

interface ISidebarViewState {}

export class SidebarView extends React.Component<ISidebarViewProps, ISidebarViewState> {
  context: AppSession;
  static contextType = AppContext;

  constructor(props: ISidebarViewProps | Readonly<ISidebarViewProps>) {
    super(props);
    this.state = {
      loading: false,
    };
    this.handleTabChanged = this.handleTabChanged.bind(this);
  }
  // Function to catch tab changes and rerender.
  handleTabChanged() {
    let tab = this.props.currentView.get();
    this.setState({ tab: tab });
    this.context.viewedViews.get(tab)!.progressLoading();
  }

  componentWillUnmount() {
    this.props.currentView.removeListener(this.handleTabChanged);
  }

  componentDidMount() {
    this.props.currentView.addListener(this.handleTabChanged);
  }
  render() {
    let items: JSX.Element[] = [];
    let selView = this.props.currentView.get();
    if (this.context.login > LoginType.None) {
      items.push(
        <div
          key={DashboardView.Home}
          className={"menuItem" + (selView === DashboardView.Home ? " selectedItem" : "")}
          onClick={() => this.props.currentView.set(DashboardView.Home)}
        >
          <Icon className="itemIcon" src={<Image.home />} />
          <span className="itemText">Home</span>
        </div>
      );

      let mngSys = this.context.userPermissions.some((item) => item.ManageSystem === true);
      let mngPub = this.context.userPermissions.some((item) => item.ManagePublishers === true);
      let mngProd = this.context.userPermissions.some((item) => item.ManageProducts === true);
      let mngSub = this.context.userPermissions.some((item) => item.ManageSubscription === true);
      let mngTit = this.context.userPermissions.some((item) => item.ManageTitles === true);
      let mngAccounts = this.context.userPermissions.some((item) => item.ManageAccounts === true);
      let mngFeed = this.context.userPermissions.some((item) => item.ManageFeedback === true);
      let mngTips = this.context.userPermissions.some((item) => item.ManageTips === true);
      let mngBulletins = this.context.userPermissions.some((item) => item.ManageBulletins === true);
      let mngRep = this.context.userPermissions.some((item) => item.ManageReporting === true);
      let mngOfflinePacks = this.context.userPermissions.some((item) => item.ManageOfflinePackages === true);
      let mngContentReview = this.context.userPermissions.some((item) => item.ManageContentReview);
      let mngAnnouncements = this.context.userPermissions.some((item) => item.ManageAnnouncements);
      let mngStoreFront = this.context.userPermissions.some((item) => item.ManageStoreFront);

      items.push(
        <div
          key={DashboardView.Profile}
          className={"menuItem" + (selView === DashboardView.Profile ? " selectedItem" : "")}
          onClick={() => this.props.currentView.set(DashboardView.Profile)}
        >
          <Icon className="itemIcon" src={<Image.user />} />
          <span className="itemText">Profile</span>
        </div>
      );

      if (mngSys || mngAccounts) {
        items.push(
          <div
            key={DashboardView.System}
            className={"menuItem" + (selView === DashboardView.System ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.System)}
          >
            <Icon className="itemIcon" src={<Image.settings />} />
            <span className="itemText">System</span>
          </div>
        );
      }
      if (mngSys || mngAccounts || mngOfflinePacks) {
        items.push(
          <div
            key={DashboardView.AccountManagement}
            className={"menuItem" + (selView === DashboardView.AccountManagement ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.AccountManagement)}
          >
            <Icon className="itemIcon" src={<Image.user />} />
            <span className="itemText">Accounts</span>
          </div>
        );
      }
      if (mngPub || mngSys) {
        items.push(
          <div
            key={DashboardView.EmailTemplates}
            className={"menuItem" + (selView === DashboardView.EmailTemplates ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.EmailTemplates)}
          >
            <Icon className="itemIcon" src={<Image.envelop />} />
            <span className="itemText">Email Templates</span>
          </div>
        );
      }
      if (mngPub || mngSys) {
        items.push(
          <div
            key={DashboardView.Billing}
            className={"menuItem" + (selView === DashboardView.Billing ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.Billing)}
          >
            <Icon className="itemIcon" src={<Image.cash />} />
            <span className="itemText">Billing</span>
          </div>
        );
        items.push(
          <div
            key={DashboardView.Publishers}
            className={"menuItem" + (selView === DashboardView.Publishers ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.Publishers)}
          >
            <Icon className="itemIcon" src={<Image.book />} />
            <span className="itemText">Publishers</span>
          </div>
        );
      }
      if (mngPub || mngSys || mngSub) {
        items.push(
          <div
            key={DashboardView.Customers}
            className={"menuItem" + (selView === DashboardView.Customers ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.Customers)}
          >
            <Icon className="itemIcon" src={<Image.customers />} />
            <span className="itemText">Customers</span>
          </div>
        );
      }
      if (mngPub || mngSys || mngProd) {
        items.push(
          <div
            key={DashboardView.Products}
            className={"menuItem" + (selView === DashboardView.Products ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.Products)}
          >
            <Icon className="itemIcon" src={<Image.products />} />
            <span className="itemText"> Products</span>
          </div>
        );
      }
      if (mngPub || mngSys || mngSub) {
        items.push(
          <div
            key={DashboardView.Subscriptions}
            className={"menuItem" + (selView === DashboardView.Subscriptions ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.Subscriptions)}
          >
            <Icon className="itemIcon" src={<Image.statsbars2 />} />
            <span className="itemText">Subscriptions</span>
          </div>
        );
      }
      if (this.context.canCurateAnySubscription()) {
        items.push(
          <div
            key={DashboardView.Licensing}
            className={"menuItem" + (selView === DashboardView.Licensing ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.Licensing)}
          >
            <Icon className="itemIcon" src={<Image.certificate />} />
            <span className="itemText">Licensing</span>
          </div>
        );
      }
      if (mngPub || mngSys || mngTit) {
        items.push(
          <div
            key={DashboardView.Titles}
            className={"menuItem" + (selView === DashboardView.Titles ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.Titles)}
          >
            <Icon className="itemIcon" src={<Image.books />} />
            <span className="itemText"> Titles</span>
          </div>
        );
      }
      if (mngPub || mngSys || mngRep) {
        items.push(
          <div
            key={DashboardView.Reporting}
            className={"menuItem" + (selView === DashboardView.Reporting ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.Reporting)}
          >
            <Icon className="itemIcon" src={<Image.info />} />
            <span className="itemText">Reporting</span>
          </div>
        );
      }
      if (mngSys || mngPub || mngTips) {
        items.push(
          <div
            key={DashboardView.Tips}
            className={"menuItem" + (selView === DashboardView.Tips ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.Tips)}
          >
            <Icon className="itemIcon" src={<Image.tip />} />
            <span className="itemText">Tips</span>
          </div>
        );
      }
      if (mngFeed || mngPub || mngSys) {
        items.push(
          <div
            key={DashboardView.Feedback}
            className={"menuItem" + (selView === DashboardView.Feedback ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.Feedback)}
          >
            <Icon className="itemIcon" src={<Image.feedback />} />
            <span className="itemText">Feedback</span>
          </div>
        );
      }
      if (mngSys || mngPub || mngBulletins) {
        items.push(
          <div
            key={DashboardView.Bulletins}
            className={"menuItem" + (selView === DashboardView.Bulletins ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.Bulletins)}
          >
            <Icon className="itemIcon" src={<Image.bulletin />} />
            <span className="itemText">Bulletins</span>
          </div>
        );
      }

      if (mngSys || mngContentReview || mngPub) {
        items.push(
          <div
            key={DashboardView.GlobalNotes}
            className={"menuItem" + (selView === DashboardView.GlobalNotes ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.GlobalNotes)}
          >
            <Icon className="itemIcon" src={<Image.annotations />} />
            <span className="itemText">Global Notes</span>
          </div>
        );
      }

      if (mngSys || mngAnnouncements || mngPub) {
        items.push(
          <div
            key={DashboardView.Announcements}
            className={"menuItem" + (selView === DashboardView.Announcements ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.Announcements)}
          >
            <Icon className="itemIcon" src={<Image.announcement />} />
            <span className="itemText">Announcements</span>
          </div>
        );
      }
      if (mngSys || mngStoreFront || mngPub) {
        items.push(
          <div
            key={DashboardView.StoreFront}
            className={"menuItem" + (selView === DashboardView.StoreFront ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.StoreFront)}
          >
            <Icon className="itemIcon" src={<Image.storefront />} />
            <span className="itemText">Storefront</span>
          </div>
        );
      }
      /* if (mngSys || mngPub) {
        items.push(
          <div
            key={DashboardView.FormEditor}
            className={"menuItem" + (selView === DashboardView.FormEditor ? " selectedItem" : "")}
            onClick={() => this.props.currentView.set(DashboardView.FormEditor)}
          >
            <Icon className="itemIcon" src={<Image.storefront />} />
            <span className="itemText">Form Editor</span>
          </div>
        );
      }*/
    }
    return (
      <div className="sidebar">
        <div className="menu">{items}</div>
      </div>
    );
  }
}
