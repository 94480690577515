import * as React from 'react';
import { Languages } from 'src/localization/Locale';
import { AppSession, LoginType } from 'src/models/AppSession';
import * as Models from 'src/models/dto/DashboardModels';
import { Drawer, DrawerContainer } from 'src/ui/foundation/Controls';
import { DataItem, DataRow, DataTable } from 'src/ui/foundation/DataTable';
import { DashboardView } from 'src/ui/foundation/Layout';
import { Action, INode, IRequest, IResponse } from 'src/ui/foundation/StandaloneCogniflow';
import { AppContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import * as Messages from '../../foundation/Messages';
import { PanelView } from '../PanelView';
import {
    PublisherLibraryConfigCreationForm
} from '../PublisherView/PublisherLibraryConfigCreationForm';
import { TitleForm } from './TitleForm';

export interface ITitleViewProps {
  IsLoggedIn: boolean;
}
export interface ITitleViewState {
  panelList: Models.ILoginLibrary[];
  selectedPublisher: number;
  name: string;
  logo: any;
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
  loading: boolean;
}
export class TitleView extends React.Component<ITitleViewProps, ITitleViewState> {
  context: AppSession;
  static contextType = AppContext;
  panelRef = React.createRef<PanelView>();
  titlesTable = React.createRef<DataTable>();
  versionsTable = React.createRef<DataTable>();
  constructor(props: ITitleViewProps | Readonly<ITitleViewProps>) {
    super(props);
    this.state = {
      panelList: [],
      selectedPublisher: 0,
      logo: "",
      name: "",
      loading: false,
      drawerShow: false,
      currentDrawerContent: null,
    };
  }
  loginInit = () => {
    let allowedPublisher = this.context.getManageableTitles();
    this.context.viewedViews.get(DashboardView.Titles)!.progressLoading();
    if (allowedPublisher.length === 0) {
      return;
    } else {
      this.setState(
        {
          panelList: allowedPublisher,
          selectedPublisher: allowedPublisher[0].PublisherId,
        },
        () => {
          this.publisherChanged(allowedPublisher[0]);
        }
      );
    }
  };
  componentDidMount() {
    this.context.viewedViews.get(DashboardView.Titles)!.loading.on(this.loginInit);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.Titles)!.loading.off(this.loginInit);
  }
  publisherChanged = (newPub: Models.ILoginLibrary) => {
    this.setState({ loading: true }, () => {
      this.setState(
        {
          selectedPublisher: newPub.PublisherId,
          name: newPub.DisplayName,
          logo: newPub.LibraryIcon,
          loading: false,
        },
        () => {
          this.reloadTitleAndDismiss();
        }
      );
    });
  };
  publisherInsertRequest = () => {
    this.setState(
      {
        drawerShow: true,
        currentDrawerContent: (
          <div>
            <PublisherLibraryConfigCreationForm selectedPublisher={0} />
          </div>
        ),
      },
      () => {
        this.reloadPanelAndDismiss();
      }
    );
  };
  Logo = () => {
    const logo = this.state.logo;
    if (logo !== "") {
      return <img title="logo" src={`data:image/jpeg;base64,${String(logo)}`} width={120} />;
    }
    return;
  };

  private titleEditRequest = (e: INode) => {
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <TitleForm
          publisherName={this.state.name}
          initialNode={e as Models.ITitle}
          saveRequested={this.saveTitle}
          deleteRequested={this.deleteTitle}
          reloadTitles={this.reloadTitleAndDismiss}
        />
      ),
    });
  };
  private reloadPanelAndDismiss = () => {
    this.setState({ currentDrawerContent: null, drawerShow: false });
  };

  private reloadTitleAndDismiss = () => {
    this.titlesTable.current!.reload();
    this.setState({ currentDrawerContent: null, drawerShow: false });
  };

  private saveTitle = async (e: Models.ITitle) => {
    let response = await this.context.insertOrUpdateTitle({
      Title: e,
    });
    if (response.valid()) {
      Messages.Notify.success(`The Title was saved successfully!`);
      this.reloadTitleAndDismiss();
      this.setState({ currentDrawerContent: null, drawerShow: false });
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };

  private deleteTitle = async (e: Models.ITitle) => {
    let dResp = await Messages.Dialog.confirm(
      <div>
        <span>Are you absolutely sure you wish to delete this title? Doing so will:</span>
        <ul>
          <li>Delete the title.</li>
        </ul>
      </div>,
      "Delete title?",
      Messages.Dialog.Buttons.DeleteCancel
    );
    if (dResp === "true") {
      let response = await this.context.deleteTitle({ Title: e });
      if (response.valid()) {
        Messages.Notify.success("Title deleted successfully!");
        this.reloadTitleAndDismiss();
        this.setState({ currentDrawerContent: null, drawerShow: false });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };

  private titleInsertRequest = () => {
    let defaultExpirationDate = new Date();
    defaultExpirationDate.setFullYear(defaultExpirationDate.getFullYear() + 5);
    let blankModel: Models.ITitle = {
      Name: "",
      TitleStatus: Models.TitleStatus.Inactive,
      ExpirationDate: defaultExpirationDate,
      PublicationDate: new Date(),
      PublisherId: this.state.selectedPublisher,
      PublicationPackId: 0,
      TableGuid: "00000000-0000-0000-0000-000000000000",
      TableId: 0,
      Index: -1,
      IsFirst: false,
      IsLast: false,
    };
    this.setState({
      drawerShow: true,
      currentDrawerContent: <TitleForm initialNode={blankModel} saveRequested={this.saveTitle} reloadTitles={this.reloadTitleAndDismiss} />,
    });
  };
  private titleQueryExecute = (query: string) => {
    this.titlesTable.current!.reload(query);
  };

  private generateTitle = (node: INode): JSX.Element => {
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];
    dataItems.push(<DataItem flexVal={2} key={1} value={node.Name} />);
    dataItems.push(<DataItem flexVal={1} key={2} className="leftBorder" value={Models.TitleStatus[node.TitleStatus]} />);
    dataItems.push(
      <DataItem flexVal={2} key={3} className="leftBorder" value={Convert.dateToFormattedString(node.PublicationDate as Date, Languages.English)} />
    );
    dataItems.push(
      <DataItem flexVal={2} key={4} className="leftBorder" value={Convert.dateToFormattedString(node.ExpirationDate as Date, Languages.English)} />
    );

    return <DataRow node={node} key={node.TableId} attributes={attrs} dataItems={dataItems} rowEditRequested={this.titleEditRequest} />;
  };

  private titleFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowTitles({
        FlowRequest: request.Batches[0],
        PublisherId: this.state.selectedPublisher,
        ExcludeTitles: [],
        HideInactiveTitles: false,
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  private initializeTitle = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowTitles({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: this.state.selectedPublisher,
        ExcludeTitles: [],
        HideInactiveTitles: false,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  render() {
    let settings = Models.genericDataSettings;
    settings.batchSize = 25;
    if (
      this.context.login === LoginType.None ||
      !this.context.canManageTitles(this.state.selectedPublisher) ||
      !this.context.viewedViews.get(DashboardView.Titles)!.isLoaded()
    ) {
      return (
        <div className="section">
          <h1>You cannot view this page</h1>
        </div>
      );
    }

    return (
      <div className="mainView" style={{ display: "flex" }}>
        <PanelView
          ref={this.panelRef}
          disabled={this.state.loading}
          publisherList={this.state.panelList}
          publisherChanged={this.publisherChanged}
          showAdd={false}
          selectedPublisher={this.state.panelList.find((x) => x.PublisherId === this.state.selectedPublisher)!}
        />
        <div className="titleView full-height full-width">
          {/* <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading Titles..."> */}
          <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
            <Drawer
              onBackdropClicked={() => {
                this.setState({ drawerShow: false, currentDrawerContent: null });
              }}
              isOpen={this.state.drawerShow}
              backdrop={true}
              className="details-view"
            >
              {this.state.currentDrawerContent}
            </Drawer>
            <div className="titleViewInner">
              <div className="section">
                <h1>Welcome to the Title View</h1>
                <p>Welcome to the Title View. This view is used to manage the various titles in the system. In this view you can:</p>
                <ul>
                  <li>Create new Titles</li>
                  <li>Modify Existing titles</li>
                  <li>Modify the publication pack of a title.</li>
                  {this.context.canManageSystem() && (
                    <li>
                      <b>Admin: </b>Delete titles.
                    </li>
                  )}
                </ul>
              </div>
              <div className="section">
                <div className="header-box">
                  <div className="content-publisher-logo">{this.Logo()}</div>
                  <h3 className="content-publisher-name">{this.state.name}</h3>
                </div>
                <hr />
                <DataTable
                  headers={["Name", "Status", "Publication Date", "Expiration Date"]}
                  headerFlexes={[2, 1, 2, 2]}
                  rowAddRequested={this.titleInsertRequest}
                  flowProvider={this.titleFlowProvider}
                  initializeFlowProvider={this.initializeTitle}
                  objectBuilder={this.generateTitle}
                  ref={this.titlesTable}
                  settingsOverride={settings}
                  searchQueryComitted={this.titleQueryExecute}
                />
              </div>
            </div>
          </DrawerContainer>
          {/* </Loading> */}
        </div>
      </div>
    );
  }
}
